import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import OutboundLink from '../../../components/OutboundLink';
import Video from '../../../components/Video';

const HowAlgaeCouldChangetheFuelIndustry = () => (
  <Layout title="How Algae Could Change the Fossil Fuel Industry">
    {/* BREADCRUMBS */}
    <Section className="breadcrumbs-section">
      <Container>
        <Row>
          <Column size={10}>
            <ul className="breadcrumbs">
              <li className="breadcrumbs__item active">
                <Link to="/economic-principles/">Economic Principles</Link>
              </li>
              <li className="breadcrumbs__item">Video Viewpoints Series</li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO GALLERY PLAYER */}
    <Section className="video-player">
      <Container>
        <Row>
          <Column size={12}>
            <h2 className="page-subtitle">Video Viewpoints Series</h2>
            <p className="intro-subtitle">
              Teaching economic principles through the lens of food, fuel and
              finance.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              guidSrc="12e88476-8fe1-40c1-aba4-08ab1ec55241"
              title="How Algae Could Change the Fossil Fuel Industry"
            />
          </Column>
          <Column size={4}>
            <div className="thumbnails">
              <div className="thumbnails__row active">
                <div className="thumbnail">
                  <Image
                    filename="img-thumb-ep-algae.jpg"
                    className="thumbnail__image"
                    alt="Financial Markets Hero Image"
                  />
                </div>
                <div className="thumbnails__content">
                  <h4>How Algae Could Change the Fossil Fuel Industry</h4>
                  <span className="thumbnails__duration">4:41min </span>
                  <p className="thumbnails__desription">
                    What can algae offer? More than you think. Come visit an
                    algae...
                  </p>
                </div>
              </div>
              <Link to="../why-the-us-needs-new-generation-of-farmers">
                <div className="thumbnails__row">
                  <div className="thumbnail">
                    <Image
                      filename="img-thumb-ep-farmers.jpg"
                      className="thumbnail__image"
                      alt="Financial Markets Hero Image"
                    />
                  </div>
                  <div className="thumbnails__content">
                    <h4>Why the U.S. Needs a New Generation of Farmers</h4>
                    <span className="thumbnails__duration">5:11min </span>
                    <p className="thumbnails__desription">
                      The future of U.S. food supply will soon be in the hands
                      of...
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="../the-impact-of-supply-chain-disruptions">
                <div className="thumbnails__row">
                  <div className="thumbnail">
                    <Image
                      filename="img-thumb-ep-supply-chain.jpg"
                      className="thumbnail__image"
                      alt="Financial Markets Hero Image"
                    />
                  </div>
                  <div className="thumbnails__content">
                    <h4>The Impact of Supply Chain Disruptions</h4>
                    <span className="thumbnails__duration">6:01min </span>
                    <p className="thumbnails__desription">
                      In a global supply chain, a single disruption has many
                      rippling...
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO DETAIL INFO */}
    <Section className="video-resources">
      <Container>
        <Row>
          <Column size={8} className="video-resources__left">
            <h1 className="page-title">
              How Algae Could Change the Fossil Fuel Industry
            </h1>
            <p>
              What can algae offer? More than you think. Come visit an algae
              pond in Hawaii where scientists study and refine ways algae could
              help fuel our future.
            </p>
            <div className="supporting-resources box-shadow br-all">
              <h3>Supporting Resources</h3>
              <p>
                Bring to life key topics covered in the video with the companion
                educator guide.
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-VideoTopicSeries-EdGuide.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.PDF)
                </OutboundLink>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="video-resources__right box-shadow br-all">
              <h3>About the Video Viewpoints Series</h3>
              <p>
                Discover the intersection of economics and innovation with a
                three-part mini documentary series exploring the ways students’
                lives connect to the global community. Each video will showcase
                stories of people and places around the globe that are shaping
                how economics are impacting life as we know it.
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP | 
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default HowAlgaeCouldChangetheFuelIndustry;
